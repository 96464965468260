import React from 'react';
import Link from 'gatsby-link';

import "prismjs";
import PrismCode from "react-prism";

import Layout from "../layouts";

export default class IndexPage extends React.Component {
	state = {
		ipinfo: {
			ip           : "loading...",
			country_short: "loading...",
			region       : "loading...",
			city         : "loading...",
			latitude     : "loading...",
			longitude    : "loading...",
			postal_code  : "loading..."
		}
	};

	componentDidMount() {
		const self = this;

		fetch("https://api.infoip.io")
			.then(res => res.json())
			.then((result) => {
				self.setState({
					ipinfo: result
				})
			});
	}

	render() {
		return <Layout>
			<section className="jumbotron">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h1>Your Ip address is<br/>
								<span>{this.state.ipinfo.ip}</span>
							</h1>
						</div>
					</div>

					<section className="list-of-properties">
						<div className="container">
							<div className="row">
								<div className="col-md-6 col-xs-6 list-left">
									<ul className="properties-list">
										<li>Country</li>
										<li>Region</li>
										<li>City</li>
										<li>Latitude/Longitude</li>
										<li>Postal Code</li>
									</ul>
								</div>
								<div className="col-md-6 col-xs-6 list-right">
									<ul className="properties-list">
										<li>{this.state.ipinfo.country_short}</li>
										<li>{this.state.ipinfo.region}</li>
										<li>{this.state.ipinfo.city}</li>
										<li>{this.state.ipinfo.latitude}/{this.state.ipinfo.longitude}</li>
										<li>{this.state.ipinfo.postal_code}</li>
									</ul>
								</div>
							</div>
						</div>
					</section>
					<br/>
					<br/>

					<div className="row">
						<div className="col-md-12">
							<Link to="/pricing" className="btn btn-first">
								API pricing &amp; docs
							</Link>
						</div>
					</div>

					<div className="row">
						<div className="gatsby-highlight">
							<PrismCode component="pre" className={"language-javascript"}>
								{`// Make your applications smarter, location-aware in just 3 lines of code

fetch('https://api.infoip.io').then((response) => {
	return response.json()
}).then((json) => alert(json.country_short === "DE" ? "guten tag!" : "Hello!"))`}
							</PrismCode>
						</div>
					</div>
				</div>
			</section>

			<section className="advantages">
				<div className="container">
					<div className="row">
						<div className="col-md-4 advantages-1">
							<h2>Daily updates</h2>
							<p>Our database is refreshed on a daily basis to ensure the information is up to date. Many
								of
								our API subscribers rely on our data to be accurate and available at all times.</p>

							<span className="image-sprite advantages-image advantages-image-1"/>
						</div>
						<div className="col-md-4 advantages-2">
							<h2>Amazing speed</h2>
							<p>Our servers run on Google's own infrastructure and all traffic is funneled through a
								load balancer that routes traffic in the zone nearest to the client making the
								request.</p>

							<span className="image-sprite advantages-image advantages-image-2"/>
						</div>
						<div className="col-md-4 advantages-3">
							<h2>Enterprise ready</h2>
							<p>Our servers are highly scalable and can witstand any number of queries. Use our API to
								gain
								valuable information about your visitors with the smallest amount of latency at a
								ridiculous
								pricing.</p>

							<span className="image-sprite advantages-image advantages-image-3"/>
						</div>

					</div>

					<div className="row ">
						<div className="col-md-12">
							<Link className="btn btn-second"
								  to="/post">
								Blog
							</Link>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	}
}
